.row .col-sm-4:nth-child(1n + 0) .card {
  background-color: #1bc5bd !important;
  color: white;
}

.row .col-sm-4:nth-child(2n + 0) .card {
  background-color: #e9e9ed !important;
  color: black;
}
.row .col-sm-4:nth-child(4n + 0) .card {
  background-color: #fff!imortant;
}

.row .col-sm-3:nth-child(1n + 0) .card {
  background-color: #1bc5bd !important;
  color: white;
}
.row .col-sm-3:nth-child(4n + 0) .card {
  background-color: #fff!imortant;
}
